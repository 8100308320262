import {
  Button,
  Divider,
  Dropdown,
  Input,
  Select,
  Switch,
  TimePicker,
} from "antd";
import classNames from "classnames";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import ApiTaskManagement from "../../../../api/task-managements";
import useSearch from "../../user-management/useSearch";
import { Chips } from "../../../../components/chips/chips";
import { Icon } from "../../../../components/icon/icon";
import ApiPlanManagement from "../../../../api/plan-management";
import dayjs from "dayjs";
import TaskCard from "../../task-management/task-card/task-card";
import { SearchOutlined } from "@ant-design/icons";
import encodeSvgWithColor from "../../../../common/utils/encodeSvgWithColor";

const DAYS = ["su", "mo", "tu", "we", "th", "fr", "sa"];

const TaskDropdownComponent = ({
  children,
  task,
  planned,
  day,
  activityId,
  replaceTaskToDay,
  deleteTask,
  updateTaskNotificationTime,
  tasksList,
  categories,
  includeInDays,
  updateReschedule,
  rescheduling,
  onOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { avatar, avatarColor, title, backgroundColor, name } = task;
  const { remindHour, remindMinute } = planned;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState();

  const close = () => {
    setIsOpen(false);
    onClose();
  };

  const handleSelectTask = () => {
    const selectTask = tasksList.find((t) => t.DailyTask.id === task.id);
    if (selectTask) {
      setSelectedTask(selectTask?.DailyTask);
      setIsOpen(true);
      onOpen();   
    } else if (task) {
      const scenarioUrl = `/administration?taskId=${task.id}#scenarios`;  // замініть на ваш реальний URL
      window.open(scenarioUrl, '_blank');
    }
  };

  const taskContentRender = useMemo(() => {
    return (
      <div className="calendar-task-modal-container">
        <div className="calendar-task-modal-header">
          <div
            className="calendar-task-modal-header-right"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {avatar ? (
              <div
                className="icon-wrapper"
                style={{
                  backgroundColor,
                }}
              >
                <img
                  src={encodeSvgWithColor(avatar, avatarColor)}
                  alt="avatar"
                />
              </div>
            ) : (
              // Цей блок не буде займати місце, якщо аватара немає
              <div
                className="icon-wrapper"
                style={{
                  display: 'none',
                }}
              />
            )}
            <div
              style={{
                marginLeft: avatar ? '10px' : '0', // Додаємо відступ, якщо є аватар
                textAlign: avatar ? 'left' : 'left', // Тайтл по лівому краю
                width: avatar ? 'auto' : '100%', // Якщо немає аватара, тайтл займає весь простір
              }}
            >
              <p className="calendar-task-modal-header-title">{title}</p>
            </div>
          </div>
          <div />
          <Button
            icon={<Icon name={"arrow-right"} />}
            style={{ background: "#fff", boxShadow: "none" }}
            onClick={handleSelectTask}
          />
        </div>
        {name && (
          <div className="calendar-task-modal-header">
            <div className="calendar-task-modal-header-right">
              <div>
                <p className="calendar-task-modal-header-title">{name}</p>
              </div>
            </div>
          </div>
        )}
        <div className="calendar-task-modal-body">
          <div className="calendar-task-modal-field">
            <p className="calendar-task-modal-field-title">
              {t("plan_management.task_modal.day")}
            </p>
            <Select
              options={DAYS.map((d) => ({
                key: d,
                label: t(`days.${d}`),
                value: d,
                disabled: includeInDays.includes(d),
              }))}
              bordered={false}
              defaultValue={{
                key: day,
                label: t(`days.${day}`),
              }}
              style={{ marginLeft: -11, width: 126 }}
              onChange={(value) => replaceTaskToDay(value, activityId)}
            />
          </div>
          <div className="calendar-task-modal-field">
            <p className="calendar-task-modal-field-title">
              {t("plan_management.task_modal.time")}
            </p>
            <TimePicker
              format={"HH:mm"}
              bordered={false}
              style={{ marginLeft: -11 }}
              defaultValue={
                Number.isInteger(remindHour) && Number.isInteger(remindMinute)
                  ? dayjs()
                      .hour(remindHour || 8)
                      .minute(remindMinute || 0)
                  : null
              }
              onChange={(value) =>
                updateTaskNotificationTime(value, activityId)
              }
            />
          </div>
          {task.title && avatar && (
            <div className="calendar-task-modal-field">
              <p className="calendar-task-modal-field-title">
                {t("plan_management.task_modal.reschedual")}
              </p>
              <Switch
                defaultChecked={rescheduling}
                onChange={(value) => updateReschedule(value, activityId)}
                size="small"
              />
            </div>
          )}
        </div>
        <div className="calendar-task-modal-footer">
          <Button
            icon={<Icon name={"delete"} />}
            style={{ marginRight: 16, background: "#fff", boxShadow: "none" }}
            onClick={() => deleteTask(activityId)}
          >
            {t(
              task.title && avatar
                ? "plan_management.task_modal.remove_task"
                : "plan_management.task_modal.remove_scenario"
            )}
          </Button>
        </div>
      </div>
    );
  }, [task, day, planned, activityId]);

    return (
      <>
        <TaskCard
          isOpen={isOpen}
          close={close}
          task={selectedTask}
          categories={categories}
          onlyView={true}
        />
        <Dropdown
          dropdownRender={() => taskContentRender}
          trigger={["click"]}
          overlayStyle={{ width: "max-content", zIndex: 100 }}
        >
          {children}
        </Dropdown>
      </>
    );
  };

const AddItemComponent = ({ items, onClick, handleAddTask, isScenario }) => {
  const { search, handleUpdateSearchValue } = useSearch();

  const list = useMemo(() => {
    const availableTasks = search
      ? items.filter((item) =>
          (item.DailyTask ? item.DailyTask.title : item.name || item.title)
            ?.toLowerCase()
            .includes(search.toLowerCase())
        )
      : items;

    return availableTasks.map((item, index) => {
      const { avatar, avatarColor, backgroundColor, title, name, id } =
        item.DailyTask || item;

      return {
        key: id + index,
        label: title ?? name,
        icon: (avatar || backgroundColor) && (
          <div
            className="calendar-icon-wrapper"
            style={{
              backgroundColor,
            }}
          >
            {avatar && (
              <img
                style={{ width: 12 }}
                src={encodeSvgWithColor(avatar, avatarColor)}
                alt="avatar"
              />
            )}
          </div>
        ),
        onClick: () => handleAddTask(item),
      };
    });
  }, [items, search]);

  const dropdownRender = (content) => {
    return (
      <div
        style={{
          padding: "0 10px",
          backgroundColor: "transparent",
        }}
      >
        <div
          className="ant-dropdown ant-dropdown-menu"
          style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
        >
          <div style={{ display: "flex", marginLeft: 15, padding: "3px" }}>
            <SearchOutlined />
            <Input
              bordered={false}
              value={search}
              placeholder="Search task"
              onChange={handleUpdateSearchValue}
            />
          </div>
          <Divider style={{ margin: "0 0 10px 0" }} />
          <div style={{ maxHeight: 260, overflow: "auto" }}>
            {React.cloneElement(content, { style: { boxShadow: "none" } })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dropdown
      menu={{
        items: list,
        onClick,
      }}
      trigger={["click"]}
      overlayStyle={{
        width: "max-content",
      }}
      dropdownRender={dropdownRender}
    >
      <div>
        <Button
          type={"text"}
          iconName={"add"}
          backgroundColor={"shade"}
          fontSize={13}
          cursor={"pointer"}
          icon={<Icon name={"add"} color={"text-1"} />}
          style={{ color: "var(--text-1)", fontSize: 13, paddingLeft: 8 }}
        >
          {isScenario ? "Add scenario" : "Add task"}
        </Button>
      </div>
    </Dropdown>
  );
};

const CalendarTask = ({
  task,
  planned,
  day,
  activityId,
  replaceTaskToDay,
  deleteTask,
  updateTaskNotificationTime,
  tasksList,
  categories,
  includeInDays,
  updateReschedule,
  rescheduling,
  id,
  isScenario,
  setActiveId
}) => {
  const { backgroundColor, title, avatarColor, name } = task || {};
  const { remindHour, remindMinute } = planned;

  const time =
    Number.isInteger(remindHour) && Number.isInteger(remindMinute)
      ? `${remindHour}:${
          remindMinute < 10 ? `0${remindMinute}` : remindMinute
        } `
      : "";

  return (
    <TaskDropdownComponent
      task={task}
      planned={planned}
      day={day}
      activityId={activityId}
      replaceTaskToDay={replaceTaskToDay}
      deleteTask={deleteTask}
      updateTaskNotificationTime={updateTaskNotificationTime}
      tasksList={tasksList}
      categories={categories}
      includeInDays={includeInDays}
      updateReschedule={updateReschedule}
      rescheduling={rescheduling}
      onOpen={() => setActiveId(id)}
      onClose={() => setActiveId(undefined)}
    >
      <div
        className={`calendar-item`}
        data-id={id || (title || name).replace(/ /g, "-")}
        style={{ marginBottom: 5, marginRight: 5, width: "100%" }}
      >
        <Chips
          text={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                lineHeight: 1.2,
              }}
            >
              <span style={{ fontWeight: 700, marginRight: 4 }}>{time}</span>
              <span>{title || name}</span>
            </div>
          }
          color={avatarColor ?? "#000000"}
          fontSize={13}
          backgroundColor={backgroundColor || "#EFEFEF"}
          borderColor={backgroundColor || "#EFEFEF"}
          iconName={!rescheduling && "locked"}
          cursor
          style={{ width: "100%", minHeight: 32 }}
        />
      </div>
    </TaskDropdownComponent>
  );
};

const PlanCalendar = ({
  planId,
  scenariosList,
  planTasks,
  addTaskToPlan,
  updateTaskPlan,
  planScenarios,
  addScenarioToPlan,
  updateScenariosPlan,
  refreshPlan,
  weekIndex
}) => {
  const weekIndexRef = useRef(weekIndex);
  useEffect(() => {
    weekIndexRef.current = weekIndex;
  }, [weekIndex]);

  const { t } = useTranslation();
  const [hoverColumnIndex, setHoverColumnIndex] = useState();
  const [tasksList, setTasksList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [id, setId] = useState(undefined);
  const [pauseHover, setPauseHoverd] = useState(false);

  const getTasks = () => {
    ApiTaskManagement.getTasks().then(({ data }) => {
      const { taskManagements, categories } = data || {};

      setTasksList(taskManagements.map((task) => ({ DailyTask: task })));
      setCategories(categories);
    });
  };

  const highlightSameItems = (e) => {
    if (pauseHover) {
      return;
    }
    if (e.target.closest(".calendar-item")) {
      const _id = e.target.closest(".calendar-item").dataset.id;
      if (id !== _id) {
        setId(e.target.closest(".calendar-item").dataset.id);
      }
    } else if (id) {
      setId(undefined);
    }
  };

  useEffect(() => {
    getTasks();
  }, []);

  useEffect(() => {
    document.addEventListener("mouseover", highlightSameItems);
    return () => document.removeEventListener("mouseover", highlightSameItems);
  }, [id]);

  const toggleOpen = (value) => {
    setId(value);
    if (value) {
      setPauseHoverd(true);
      return;
    }
    setPauseHoverd(false);
  };

  const viewAddBtn = (index) => {
    setHoverColumnIndex(index);
  };

  const addTask = (task, index) => {
    const addedTask = { ...task, day: DAYS[index], weekIndex: weekIndexRef.current };
    ApiPlanManagement.updateWeeklySchedule(planId, addedTask).then(() => {
      refreshPlan();
    });
    addTaskToPlan(addedTask);
  };

  const addScenario = (scenario, index, dailyScenario = []) => {
    const addedScenario = { ...scenario, day: DAYS[index], weekIndex: weekIndexRef.current };

    const lastOfDailyScenarioTime =
      dailyScenario[dailyScenario.length - 1]?.remindHour;

    let remindHour = scenario.remindHour;

    if (!remindHour && Number.isInteger(lastOfDailyScenarioTime)) {
      remindHour =
        lastOfDailyScenarioTime < 23 ? lastOfDailyScenarioTime + 1 : 23;
    }

    const newScenario = {
      scenarioId: scenario.id,
      day: DAYS[index],
      Scenario: scenario,
      remind: true,
      remindHour: remindHour ?? 7,
      remindMinute: scenario.remindMinute ?? 0,
      weekIndex: weekIndexRef.current
    };
    ApiPlanManagement.updateWeeklySchedule(planId, newScenario).then(() => {
      refreshPlan();
    });

    addScenarioToPlan(addedScenario);
  };

  const deleteTask = (activityId) => {
    ApiPlanManagement.deleteActivity(planId, activityId).then(() => {
      refreshPlan();
    });
  };

  const deleteScenario = (activityId) => {
    ApiPlanManagement.deleteActivity(planId, activityId).then(() => {
      refreshPlan();
    });
  };

  const replaceTaskToDay = (newDay, activityId) => {
    const changedTask = planTasks.find(
      (p) => (p.id || p.DailyTask.id) === activityId
    );
    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedTask,
      day: newDay,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
    });

    const newPlan = planTasks.map((task) =>
      task.id === activityId ? { ...task, day: newDay } : task
    );
    updateTaskPlan(newPlan);
  };

  const replaceScenarioToDay = (newDay, activityId) => {
    const changedScenario = planScenarios.find((p) => p.id === activityId);
    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedScenario,
      day: newDay,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
    });

    const newPlan = planScenarios.map((scenario) =>
      scenario.id === activityId ? { ...scenario, day: newDay } : scenario
    );

    updateScenariosPlan(newPlan);
  };

  const updateReschedule = (rescheduling, activityId) => {
    const changedTask = planTasks.find(
      (p) => (p.id || p.DailyTask.id) === activityId
    );

    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedTask,
      rescheduling,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
    });

    const newPlan = planTasks.map((task) =>
      task.id === activityId ? { ...task, rescheduling } : task
    );
    updateTaskPlan(newPlan);
  };

  const updateScenarioReschedule = (rescheduling, activityId) => {
    const changedScenario = planScenarios.find((p) => p.id === activityId);

    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedScenario,
      rescheduling,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
    });

    const newPlan = planScenarios.map((scenario) =>
      scenario.id === activityId ? { ...scenario, rescheduling } : scenario
    );

    updateScenariosPlan(newPlan);
  };

  const updateTaskNotificationTime = (time, activityId) => {
    const remindHour = time.$H;
    const remindMinute = time.$m;

    const changedTask = planTasks.find(
      (p) => (p.id || p.DailyTask.id) === activityId
    );
    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedTask,
      remind: true,
      remindHour,
      remindMinute,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
    });

    const newPlan = planTasks.map((task) =>
      task.id === activityId
        ? { ...task, remind: true, remindHour, remindMinute }
        : task
    );
    updateTaskPlan(newPlan);
  };

  const updateScenarioNotificationTime = (time, activityId) => {
    const remindHour = time.$H;
    const remindMinute = time.$m;

    const changedScenario = planScenarios.find((p) => p.id === activityId);
    ApiPlanManagement.updateWeeklySchedule(planId, {
      ...changedScenario,
      remind: true,
      remindHour,
      remindMinute,
      weekIndex: weekIndexRef.current
    }).then(() => {
      refreshPlan();
    });

    const newPlan = planScenarios.map((scenario) =>
      scenario.id === activityId
        ? { ...scenario, remind: true, remindHour, remindMinute }
        : scenario
    );

    updateScenariosPlan(newPlan);
  };

  const calendarDaysRender = useMemo(() => {
    const tasksForHandleSelect = (index) => {
      const todayPlannedTasks =
        planTasks?.filter((pt) => pt.day === DAYS[index]) || [];
      if (todayPlannedTasks.length) {
        return tasksList.filter(
          (t) =>
            !todayPlannedTasks.find(
              (task) => task.DailyTask.id === t.DailyTask.id
            )
        );
      }
      return tasksList;
    };

    const scenariosForHandleSelect = (index) => {
      const todayPlannedScenarios =
        planScenarios?.filter((pt) => pt.day === DAYS[index]) || [];
      if (todayPlannedScenarios.length) {
        return scenariosList.filter(
          (t) => !todayPlannedScenarios.find((scenario) => scenario.id === t.id)
        );
      }
      return scenariosList;
    };

    return (
      <div className={"plan-calendar"}>
        <div className="calendar-columns-container">
          {DAYS.map((day, index) => (
            <p
              className={classNames("calendar-day", {
                "calendar-columns-items": index,
              })}
            >
              {t(`days.${day}`)}
            </p>
          ))}
          {DAYS.map((day, index) => (
            <div className={index && "calendar-columns-items"}>
              <div
                key={day + "calendar-column"}
                className="calendar-column"
                onMouseOver={() => viewAddBtn(index)}
                onMouseLeave={viewAddBtn}
              >
                {planTasks
                  ?.filter((planTask) => planTask.day === day)
                  .sort(
                    (a, b) =>
                      a.remindHour - b.remindHour ||
                      a.remindMinute - b.remindMinute
                  )
                  .map((task) => {
                    const { remindHour, remindMinute, day, id, rescheduling } =
                      task;
                    const planned = { remindHour, remindMinute };
                    const includeInDays = planTasks
                      ?.filter((pt) => pt.dailyTaskId === task.dailyTaskId)
                      .map((dt) => dt.day);
                    return (
                      <CalendarTask
                        key={id}
                        id={task?.DailyTask?.id}
                        task={task.DailyTask ?? task}
                        includeInDays={includeInDays}
                        rescheduling={rescheduling ?? true}
                        planned={planned}
                        day={day}
                        activityId={id}
                        replaceTaskToDay={replaceTaskToDay}
                        deleteTask={deleteTask}
                        updateTaskNotificationTime={updateTaskNotificationTime}
                        tasksList={tasksList}
                        categories={categories}
                        updateReschedule={updateReschedule}
                        setActiveId={toggleOpen}
                      />
                    );
                  })}
                <div
                  className={classNames({
                    "calendar-add-btn--hidden": hoverColumnIndex !== index,
                  })}
                >
                  <AddItemComponent
                    items={tasksForHandleSelect(index)}
                    handleAddTask={(task) => addTask(task, index, weekIndexRef.current)}
                  />
                </div>
              </div>
            </div>
          ))}

          {DAYS.map((day, index) => (
            <div
              key={day + "calendar-scenario-column"}
              className={classNames("calendar-scenario-column", {
                "calendar-columns-items": index,
              })}
              onMouseOver={() => viewAddBtn(index + 7)}
              onMouseLeave={viewAddBtn}
            >
              {planScenarios
                ?.filter((planScenario) => planScenario.day === day)
                .sort(
                  (a, b) =>
                    a.remindHour - b.remindHour ||
                    a.remindMinute - b.remindMinute
                )
                .map((scenario) => {
                  const { remindHour, remindMinute, day, id, rescheduling } =
                    scenario;
                  const planned = { remindHour, remindMinute };
                  const includeInDays = planScenarios
                    ?.filter((pt) => pt.id === scenario.id)
                    .map((dt) => dt.day);
                  return (
                    <CalendarTask
                      key={id}
                      id={scenario?.Scenario?.id}
                      task={scenario.Scenario ?? scenario}
                      includeInDays={includeInDays}
                      rescheduling={rescheduling ?? true}
                      planned={planned}
                      day={day}
                      activityId={id}
                      replaceTaskToDay={replaceScenarioToDay}
                      deleteTask={deleteScenario}
                      updateTaskNotificationTime={
                        updateScenarioNotificationTime
                      }
                      tasksList={tasksList}
                      categories={categories}
                      updateReschedule={updateScenarioReschedule}
                      setActiveId={toggleOpen}
                    />
                  );
                })}
              <div
                className={classNames({
                  "calendar-add-btn--hidden": hoverColumnIndex !== index + 7,
                })}
              >
                <AddItemComponent
                  isScenario
                  items={scenariosForHandleSelect(index)}
                  handleAddTask={(scenario) =>
                    addScenario(
                      scenario,
                      index,
                      planScenarios
                        .filter((planScenario) => planScenario.day === day)
                        .sort(
                          (a, b) =>
                            a.remindHour - b.remindHour ||
                            a.remindMinute - b.remindMinute
                        )
                    )
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }, [
    planTasks,
    hoverColumnIndex,
    tasksList,
    planId,
    scenariosList,
    planScenarios,
  ]);

  return (
    <div className="calendar">
      <style>
        {id
          ? `
              .calendar-item{
                opacity:0.4;
              }
              [data-id="${id}"]{
                opacity:1;
              }
            `
          : ""}
      </style>
      {calendarDaysRender}
    </div>
  );
};

export default PlanCalendar;
