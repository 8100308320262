import { useEffect, useMemo, useState } from "react";
import { DrawerHeader } from "../../../../components/drawer/drawer-header";
import { Drawer } from "../../../../components/drawer/drawer";
import { Form } from "antd";
import {
  FormHeaderTextarea,
  FormImage,
  FormSelect,
  FormSwitch,
  FormInput
} from "../../../../components/form/form";
import { Editor } from "../../../../components/editor/editor";
import TagsSelect from "../../../../components/tags-select/tags-select";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/slices/user";
import Markdown from "react-markdown";
import ApiArticles from "../../../../api/articles";

export const ArticleModal = ({
  form,
  data,
  onClose,
  onSave,
  tags,
  categories,
  articleId,
  updateHasImage,
  updateIsUpdatedImage,
  isLoading,
  authors = [],
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editorContent, setEditorContent] = useState();
  const [articleImage, setArticleImage] = useState();
  const [isExternal, setIsExternal] = useState(data?.external ?? false);

  const currentUser = useSelector(selectUser);

  const closeHandler = () => {
    setEditMode(false);
    onClose();
  };

  const editHandler = () => {
    setEditMode(true);
  };

  const saveHandler = async () => {
    await onSave()?.finally?.(() => {
      setEditMode(false);
    });
  };

  const cancelHandler = () => {
    form.resetFields();
    setEditMode(false);
    if (data.status === "draft") {
      onClose();
    }
  };

  const onHandleContentUpdate = (value) => {
    setEditorContent(value);
  };

  const onHandleUpdateImage = (image) => {
    setArticleImage(image);
    form.setFieldValue(
      "image",
      image
    )
  };

  useEffect(() => {
    if (data?.status === "draft") {
      setEditMode(true);
    }

    if (data?.content) {
      setEditorContent(data.text);
    }

    if (data) {
      setArticleImage(data.image);
      setIsExternal(data?.external);
    }
  }, [data]);

  const updateArticleTags = (values) => {
    form.setFieldValue("tags", values);
  };

  const updateCategory = (category) => {
    form.setFieldValue("categoryId", category);
  };

  const updateImageStatus = (value) => {
    updateHasImage(value);
  };

  const updateAuthor = (author) => {
    form.setFieldValue(
      "userId",
      authors.find((a) => a.fullName === author)?.id
    );
  };

  const updateIsExternal = (value) => {
    form.setFieldValue("external", value)
    setIsExternal(value);
  };

  const drawerContent = useMemo(() => {
    return data ? (
      <Form form={form}>
        <DrawerHeader
          title={
            <Form.Item name={"title"} initialValue={data.title}>
              <FormHeaderTextarea readonly={!editMode} placeholder={"Title"} />
            </Form.Item>
          }
          onEdit={editHandler}
          onSave={saveHandler}
          onCancel={cancelHandler}
          onClose={closeHandler}
          isEditing={editMode}
          isLoading={isLoading}
        />

        <Form.Item name={"categoryId"} initialValue={data.categories}>
          <div className="article-modal-tags-label-wrapper">
            <span className="article-modal-tags-label">Category: </span>
          </div>
          <FormSelect
            options={categories.map((c) => ({
              value: c.id,
              label: c.name,
              key: c.id,
            }))}
            defaultValue={categories
              .map((cat) => ({value: cat.id, key: cat.id, label: cat.name}))
              .find((c) => c.value === data.categoryId)}
            onSelect={updateCategory}
            placeholder={editMode && "Select category..."}
            style={{minWidth: 160}}
            bordered={false}
            readonly={!editMode}
          />
        </Form.Item>
        <Form.Item name={"tags"} initialValue={data.tags}>
          <div className="article-modal-tags-label-wrapper">
            <span className="article-modal-tags-label">Tags: </span>
          </div>
          <div style={{ paddingLeft: 10 }}>
            <TagsSelect
              options={tags}
              defaultValue={data.tags}
              onChange={updateArticleTags}
              onCreate={updateArticleTags}
              readonly={!editMode}
              placeholder="+ Add Tag"
              isEditing={true}
            />
          </div>
        </Form.Item>
        <Form.Item
          name={"userId"}
          initialValue={authors.find((a) => a.id === data.userId)?.id}
        >
          <div className="article-modal-tags-label-wrapper">
            <span className="article-modal-tags-label">Author: </span>
          </div>
          <FormSelect
            placeholder={editMode && "Select author"}
            options={authors?.map((author) => ({
              id: author.id,
              value: author.fullName,
            }))}
            defaultValue={() => {
              const usr =
                authors.find((a) => a.id === data.userId) || currentUser;
              return usr ? {id: usr?.id, value: usr?.fullName} : null;
            }}
            onSelect={updateAuthor}
            readonly={!editMode}
          />
        </Form.Item>
        <Form.Item name={"link"} initialValue={data.link}>
          <div className="article-modal-tags-label-wrapper">
            <span className="article-modal-tags-label">Source URL: </span>
          </div>
          <FormInput
            defaultValue={data.link}
            readonly={!editMode}
            placeholder={editMode ? 'Source URL' : ''}
            onChange={(e) => {
              form.setFieldValue("link", e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item name={"external"} initialValue={isExternal}>
          <div className="article-modal-tags-label-wrapper">
            <span className="article-modal-tags-label">External Article: </span>
          </div>
          <FormSwitch
            key={'ext-key ' + isExternal}
            readOnly={!editMode}
            checked={isExternal}
            onChange={updateIsExternal}
          />
        </Form.Item>
        <Form.Item name={"image"} initialValue={articleImage}>
          <FormImage
            readonly={!editMode}
            onChange={onHandleUpdateImage}
            articleId={articleId}
            updateImageStatus={updateImageStatus}
            updateIsUpdatedImage={updateIsUpdatedImage}
            fetchIconApi={ApiArticles.fetchIcon}
            form={form}
            fetchFromFormKeyAutorized="external"
            fetchFromFormKeyUrl="link"
          />
        </Form.Item>
        {!isExternal ? (
          <Form.Item name={"text"} initialValue={editorContent}>
            {editMode ? (
              <Editor
                content={data.text}
                readonly={!editMode}
                onChange={onHandleContentUpdate}
              />
            ) : (
              <Markdown className="markdown-editor">{data.text}</Markdown>
            )}
          </Form.Item>
          ) : null}
      </Form>
    ) : null;
  }, [data, articleImage, editMode, editorContent, isExternal]);

  return (
    <Drawer open={!!data} onClose={closeHandler} destoyOnClose={true}>
      {drawerContent}
    </Drawer>
  );
};
